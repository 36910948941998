import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Index from "./jsx";

import { SuperadminloginApi } from "./services/superadminService/SuperAdminAuthService";
import {
  adminloginApi,
  adminloginWithTokenApi as validateTokenApi,
} from "./services/AuthService";
import "./css/style.css";
import DelayedError404 from "./jsx/components/DelayedError/DelayedError404";
import { AuthContext } from "./context/AuthContext";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App() {
  const navigate = useNavigate();
  const { fetchLoginDetails } = useContext(AuthContext);
  const [authStatus, setAuthStatus] = useState({
    isLoading: true,
    isAuthenticated: false,
  });

  useEffect(() => {
    const checkAuthentication = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const queryToken = queryParams.get("token");

      try {
        let isAuthenticated = false; // Local variable to track authentication status
        // Priority 1: Check Query Token
        if (queryToken) {
          const tokenValidation = await validateTokenApi({ token: queryToken });
          if (tokenValidation.status === 200) {
            const loginDetails = tokenValidation?.data?.response;

            // Secure token storage
            localStorage.setItem("access_token_admin", loginDetails?.token);
            localStorage.setItem("login_Details", JSON.stringify(loginDetails));

            isAuthenticated = true; // Update local variable
            setAuthStatus({ isLoading: false, isAuthenticated: true });
            fetchLoginDetails();
            console.log(3);
            console.log("Authenticated via query token");
            navigate("/", { replace: true });
            return;
          }
        }

        // Priority 2: Check Stored Token
        const storedToken = localStorage.getItem("access_token_admin");
        const storedLoginDetails = localStorage.getItem("login_Details");

        if (storedToken && storedLoginDetails) {
          isAuthenticated = true; // Update local variable
          setAuthStatus({ isLoading: false, isAuthenticated: true });
          return;
        }

        // If no valid authentication found
        setAuthStatus({ isLoading: false, isAuthenticated: false });
      } catch (error) {
        console.error("Authentication check failed:", error);
        setAuthStatus({ isLoading: false, isAuthenticated: false });
      }
    };

    checkAuthentication();
  }, [navigate]);

  // Loading State Render
  if (authStatus.isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  // Authentication Routes
  const authRoutes = (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Login apifun={adminloginApi} />} />
        <Route path="/admin-login" element={<Login apifun={adminloginApi} />} />
        <Route
          path="/super-admin-login"
          element={<Login apifun={SuperadminloginApi} />}
        />
        <Route path="/page-register" element={<SignUp />} />
        <Route path="/page-forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<DelayedError404 />} />
      </Routes>
    </Suspense>
  );
  console.log("Auth Status: is", authStatus.isAuthenticated);

  // Render based on authentication status
  return authStatus.isAuthenticated ? (
    <Suspense fallback={<div>Loading...</div>}>
      <Index />
    </Suspense>
  ) : (
    <div className="vh-100">{authRoutes}</div>
  );
}

export default App;
